import DefaultStyle from "./default";

const MapBoxLayers = {};

MapBoxLayers['where-streets'] = {
    label: 'Streets',
    ...DefaultStyle,
    tags: ['Mapbox', 'osm', 'roads'],
};

MapBoxLayers['mapbox-streets-v11'] = {
    label: 'Streets-v11',
    styleUrl: 'mapbox://styles/mapbox/streets-v11',
    tags: ['Mapbox', 'osm', 'roads'],
};

MapBoxLayers['mapbox-dark-v10'] = {
    label: 'Dark',
    styleUrl: 'mapbox://styles/mapbox/dark-v10',
    tags: ['Mapbox', 'osm', 'roads'],
};

MapBoxLayers['mapbox-outdoors-v11'] = {
    label: 'Outdoors',
    styleUrl: 'mapbox://styles/mapbox/outdoors-v11',
    tags: ['Mapbox', 'osm', 'roads'],
};

MapBoxLayers['mapbox-satellite-v9'] = {
    label: 'Satellite',
    styleUrl: 'mapbox://styles/mapbox/satellite-v9',
    tags: ['Mapbox', 'maxar', 'satellite'],
};

export default MapBoxLayers;
