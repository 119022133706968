
function _defineProperties(target, props) {
  for (var i = 0; i < props.length; i++) {
    var descriptor = props[i];
    descriptor.enumerable = descriptor.enumerable || false;
    descriptor.configurable = true;
    if ("value" in descriptor) descriptor.writable = true;
    Object.defineProperty(target, descriptor.key, descriptor);
  }
}

function _createClass(Constructor, protoProps, staticProps) {
  if (protoProps) _defineProperties(Constructor.prototype, protoProps);
  if (staticProps) _defineProperties(Constructor, staticProps);
  return Constructor;
}


/**
 * Adds style switcher similar to Google Maps.
 * @param {Object} options
 * @param {Array} [options.styles] - Array of style objects:
 * @param {String} options.styles.label - Style label to display on switcher
 * @param {String} options.styles.styleName - [Style name from spec](https://docs.mapbox.com/mapbox-gl-js/style-spec/#root-name)
 * @param {String} options.styles.styleUrl - Style url
 */

const StylesControl = function () {
  function StylesControl() {
    var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

    this.styles = options.styles;
  }

  _createClass(StylesControl, [{
    key: "insertControls",
    value: function insertControls() {
      var self = this;

      this.container = document.createElement('div');
      this.container.classList.add('mapboxgl-ctrl');
      this.container.classList.add('mapboxgl-ctrl-group');
      this.container.classList.add('mapboxgl-ctrl-styles');
      this.buttons = [];
      let first = true;
      this.styles.forEach(function (style) {
        var button = document.createElement('button');
        button.setAttribute('type', 'button');
        button.textContent = style.label;
        if (first) {
          button.classList.add('active');
          first = false;
        }
        button.addEventListener('click', function () {
          if (button.classList.contains('active')) return;

          self.map.setStyle(style.styleUrl || style);
          self.container.querySelectorAll('button').forEach(b => b.classList.remove('active'))
          button.classList.add('active');
          
        });

        self.buttons.push(button);

        self.container.appendChild(button);
      });
    }
  }, {
    key: "onAdd",
    value: function onAdd(map) {
      this.map = map;
      this.insertControls();
      return this.container;
    }
  }, {
    key: "onRemove",
    value: function onRemove() {
      this.container.parentNode.removeChild(this.container);
      this.map = undefined;
    }
  }]);

  return StylesControl;
}();


function addMobilePitchGesture(mbMap) {
  const minDiffX = 70; // min x distance to recognize pitch gesture
  const maxDiffY = 100; // max y distance to recognize pitch gesture
  const minDiff = 30; // min distance to recognize zoom gesture
  const delay = 160; // delay for pitch, in case it's a zoom gesture

  let dpPoint;
  let dpPitch;
  let startTiming;
  let startDistance;
  let startEventData;

  mbMap
  .on("touchstart", data => {
    if (data.points.length === 2) {
      const diffY = data.points[0].y - data.points[1].y;
      const diffX = data.points[0].x - data.points[1].x;
      if (Math.abs(diffX) >= minDiffX && Math.abs(diffY) <= maxDiffY) {
        data.originalEvent.preventDefault(); // prevent browser refresh on pull down
        mbMap.touchZoomRotate.disable(); // disable native touch controls
        mbMap.dragPan.disable();
        dpPoint = data.point;
        dpPitch = mbMap.getPitch();
        startTiming = Date.now();
        startDistance = Math.hypot(diffX, diffY);
        startEventData = data;
      }
    }
  })
  .on("touchmove", data => {
    if (dpPoint !== undefined && dpPitch !== undefined) {
      data.preventDefault();
      data.originalEvent.preventDefault();

      const diffY = data.points[0].y - data.points[1].y;
      const diffX = data.points[0].x - data.points[1].x;
      const distance = Math.hypot(diffX, diffY);

      if (Math.abs(distance - startDistance) >= minDiff) {
        if (dpPoint) {
          mbMap.touchZoomRotate.enable();
          mbMap.dragPan.enable();
          mbMap.touchZoomRotate.onStart(
            Date.now() - startTiming >= delay
              ? data.originalEvent
              : startEventData.originalEvent
          );
        }
        dpPoint = undefined;
        return;
      }

      if (Date.now() - startTiming >= delay) {
        const diff = (dpPoint.y - data.point.y) * 0.5;
        mbMap.setPitch(dpPitch + diff);
      }
    }
  })
  .on("touchend", () => {
    if (dpPoint) {
      mbMap.touchZoomRotate.enable();
      mbMap.dragPan.enable();
    }
    dpPoint = undefined;
  })
  .on("touchcancel", () => {
    if (dpPoint) {
      mbMap.touchZoomRotate.enable();
      mbMap.dragPan.enable();
    }
    dpPoint = undefined;
  });
}

// Control implemented as ES6 class
class LanguageSelectorControl {
  onAdd(map) {
    window.Where = { language: 'fr' };
    this.map = map;
    var self = this;

    this.container = document.createElement('div');
    this.container.classList.add('mapboxgl-ctrl');
    this.container.classList.add('mapboxgl-ctrl-group');
    this.container.classList.add('mapboxgl-ctrl-lang');
    this.buttons = [];
    ['fr', 'ar'].forEach(function (lang) {
      var button = document.createElement('button');
      button.setAttribute('type', 'button');
      button.textContent = lang;
      button.addEventListener('click', function () {
        if (button.classList.contains('active')) return;
        window.Where.language = button.textContent;
        self.map.getStyle().layers.forEach(layer => {
          if (layer.id.match(/-label$/)) {
            let label;
            switch (window.Where.language) {
              case 'fr': label = ['coalesce', ['get', 'name_fr'], ['get', 'name_en'], ['get', 'name']]; break;
              case 'ar': label = ['coalesce', ['get', 'name_ar'], ['get', 'name']]; break;
              default: label = ['get', 'name'];
            }

            self.map.setLayoutProperty(layer.id, 'text-field', label);
          }
        });
        self.container.querySelectorAll('button').forEach(b => b.classList.remove('active'))
        button.classList.add('active');
        
      });

      self.buttons.push(button);

      self.container.appendChild(button);
    });

    return this.container;
  }

  onRemove() {
      this._container.parentNode.removeChild(this._container);
      this.map = undefined;
  }
}


export {
  StylesControl,
  addMobilePitchGesture,
  LanguageSelectorControl
};
