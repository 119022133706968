/**
 * 
 * 
 * QuadKeys: https://docs.microsoft.com/en-us/bingmaps/articles/bing-maps-tile-system
 * @see https://docs.microsoft.com/en-us/bingmaps/articles/
 */
export const GoogleMapMaker = {
    label: "Google Map Maker",
    type: "raster",
    desc: "Equivalent to Google Map Streets",
    remoteTiles: "https://mt{cdn}.google.com/vt/hl=x-local&source=mapmaker&x={x}&y={y}&z={z}",
    cdns: [1, 2, 3],
    localTiles: "GoogleMapMaker/tiles",
    attribution: 'Map tiles and data by <a href="https://www.google.com/maps/" target="google_street">Google</a>',
    tags: ['Google', 'roads'],
    minzoom: 0,
    maxzoom: 22,
};

export const NokiaStreets = {
    label: "Nokia Streets",
    type: "raster",
    remoteTiles: "https://{cdn}.base.maps.api.here.com/maptile/2.1/maptile/newest/normal.day/{z}/{x}/{y}/256/png8?app_id=bC4fb9WQfCCZfkxspD4z&app_code=K2Cpd_EKDzrZb1tz0zdpeQ&lg=eng'",
    localTiles: "Nokia/tiles/",
    cdns: [1, 2, 3],
    tags: ['Nokia', 'roads'],
    attribution: 'Map tiles and data by <a href="https://wego.here.com/" target="_osorg">Nokia HERE WeGo</a>',
    minzoom: 0,
    maxzoom: 20,
};

export const OpenStreetMap = {
    label: "OpenStreetMap",
    type: "raster",
    desc: "Default tiles found on the main website",
    remoteTiles: "https://{cdn}.tile.openstreetmap.org/{z}/{x}/{y}.png",
    attribution: 'Map tiles and data by <a href="https://www.openstreetmap.org/" target="_osorg">OpenStreetMap</a>',
    cdns: ['a', 'b', 'c'],
    tags: ['osm', 'roads'],
    minzoom: 0,
    maxzoom: 19,
};

export const OpenStreetMapFr = {
    label: "OpenStreetMap.fr",
    type: "raster",
    remoteTiles: "https://{cdn}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png",
    cdns: ['a', 'b', 'c'],
    tags: ['osm', 'roads'],
    attribution: 'Map tiles by <a href="https://www.openstreetmap.fr/" target="_osmfr">OpenStreetMap France</a>. Data by <a href="https://www.openstreetmap.org/" target="_osorg">OpenStreetMap</a>',
    minzoom: 0,
    maxzoom: 20,
};

export const VirtualEarthSatellite = {
    label: "VirtualEarth Satellite",
    type: "raster",
    //remoteTiles: "http://r{cdn}.ortho.tiles.virtualearth.net/tiles/r/{quadkey}.png?g=392",
    remoteTiles: "https://t.ssl.ak.dynamic.tiles.virtualearth.net/comp/ch/{quadkey}?mkt=en-US&it=A,G,RL&shading=hill&n=z&og=677&c4w=1&src=h",
    attribution: 'Map tiles and data by <a href="http://www.viavirtualearth.com/" target="_ve">Virtual Earth</a>',
    cdns: [1, 2, 3],
    tags: ['satellite'],
    minzoom: 0,
    maxzoom: 18,    
};

export const StamenArt = {
    label: "Stamen Art",
    type: "raster",
    remoteTiles: "https://stamen-tiles.a.ssl.fastly.net/watercolor/{z}/{x}/{y}.jpg",
    attribution: 'Map tiles by <a target="_stamen" rel="noopener" href="http://stamen.com">Stamen Design</a>, under <a target="_top" rel="noopener" href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a>. Data by <a target="_top" rel="noopener" href="http://openstreetmap.org">OpenStreetMap</a>, under <a target="_top" rel="noopener" href="http://creativecommons.org/licenses/by-sa/3.0">CC BY SA</a>',
    tags: ['osm', 'art'],
    minzoom: 0,
    maxzoom: 16,
};

export const EsriTopo = {
    label: "Esri Topo",
    type: "raster",
    remoteTiles: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Shaded_Relief/MapServer/tile/{z}/{y}/{x}.jpg",
    attribution: 'Map tiles and data by <a href="https://www.esri.com/" target="esri">Esri</a>',
    tags: ['topo',],
    minzoom: 0,
    maxzoom: 13,
};


export const ViaMichelin = {
    label: "Via Michelin",
    type: "raster",
    remoteTiles: "https://map{cdn}.viamichelin.com/map/mapdirect?map=viamichelin&z={z}&x={x}&y={y}&format=png&version=201906241753&layer=background&locale=default&cs=1&protocol=https",
    cdns: [1, 2, 3],
    attribution: 'Map tiles and data by <a href="https://www.viamichelin.com/" target="_michelin">ViaMichelin</a>',
    tags: ['raster'],
    minzoom: 0,
    maxzoom: 19,
};



// Layers['apple-mapkit-satellite'] = {
//     label: 'Apple Mapkit',
//     styleUrl: {
//         'version': 8,
//         'sources': {
//             'apple-mapkit-satellite': {
//                 'type': 'raster',
//                 'tiles': [
//                     'https://a.tile.where.tn:9437/apple/{z}/{x}/{y}.jpg',
//                     'https://b.tile.where.tn:9437/apple/{z}/{x}/{y}.jpg',
//                     'https://c.tile.where.tn:9437/apple/{z}/{x}/{y}.jpg',
//                     'https://d.tile.where.tn:9437/apple/{z}/{x}/{y}.jpg',
//                 ],
//                 'minzoom': 0,
//                 'maxzoom': 18,
//                 'tileSize': 256,
//             }
//         },
//         'layers': [
//             {
//                 'id': 'apple-mapkit',
//                 'type': 'raster',
//                 'source': 'apple-mapkit-satellite',
//                 'minzoom': 0,
//                 'maxzoom': 22
//             }
//         ]
//     }
// };

// Layers['apple-mapkit-satellite-downloader'] = {
//     label: 'Apple Mapkit',
//     styleUrl: {
//         'version': 8,
//         'sources': {
//             'apple-mapkit-satellite': {
//                 'type': 'raster',
//                 'tiles': [
//                     'https://tiles.where.tn/apple/{z}/{x}/{y}.jpg',
//                 ],
//                 'minzoom': 0,
//                 'maxzoom': 18,
//                 'tileSize': 256,
//             }
//         },
//         'layers': [
//             {
//                 'id': 'apple-mapkit',
//                 'type': 'raster',
//                 'source': 'apple-mapkit-satellite',
//                 'minzoom': 0,
//                 'maxzoom': 22
//             }
//         ]
//     }
// };



// function getQuadkey(z, x, y) {
//     let quadkey = '', mask;
//     for (let i = z; i > 0; i--) {
//         mask = 1 << (i - 1);
//         quadkey += ((x & mask ? 1 : 0) + (y & mask ? 2 : 0));
//     }
//     return quadkey;
// }
