/**
 * All layers
 */


function ConfigToStyle(config) {
    
    const uid = config.label.replace(/\s/g, '');
    const srcId = `source-${uid}`;
    const layerId = `layer-${uid}`;

    const Style = {
        label: config.label,
        styleUrl: {
            version: 8,
            sources: {},
            layers: [{
                id: layerId,
                type: 'raster',
                source: srcId,
                minzoom: 0,
                maxzoom: 22
            }]
        }
    };
    
    Style.styleUrl.sources[srcId] = {
        type: 'raster',
        tiles: [ ],
        tileSize: config.tileSize || 256,
        minzoom: config.minzoom || 0,
        maxzoom: config.maxzoom || 22,
        attribution: config.attribution || ""
    }

    if (config.cdns) {
        config.cdns.forEach(cdn => 
            Style.styleUrl.sources[srcId].tiles.push(
                config.remoteTiles.replace('{cdn}', cdn)
            )
        )
    } else {
        Style.styleUrl.sources[srcId].tiles.push(config.remoteTiles);
    }


    return Style;
}

import MapBoxLayers from "./mapbox";
import { 
    GoogleMapMaker,
    OpenStreetMap,
    OpenStreetMapFr,
    VirtualEarthSatellite,

} from "./others.js";

export const AllLayers = {
    ...MapBoxLayers,
    OpenStreetMap: ConfigToStyle(OpenStreetMap),
    OpenStreetMapFr: ConfigToStyle(OpenStreetMapFr),
    GoogleMapMaker: ConfigToStyle(GoogleMapMaker),
    VirtualEarthSatellite: ConfigToStyle(VirtualEarthSatellite),
};

